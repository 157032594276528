DIV.collection_tile {
    border-radius: 4px;
    border: #e6e6e6 0px solid;
}

DIV.collection_img_container {
    border-radius: 8px;
    width:200px;
    float:right;
}

IMG.collection_img {
    margin: 0 0 0 0;
    width: 100%;
  display: block;
  object-fit: cover;
}