/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
    margin-bottom: 4rem;
  }
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }
  
  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 32rem;
  }
  
  IMG.home_carousel_1_img {
  }

  IMG.home_carousel_2_img {
  }


  IMG.carousel_right_side {
    position:absolute;
    top: -300px;
    left: 500px;
  }

  IMG.carousel_left_side {
    position:absolute;
    top: -330px;
    left: 0px;
  }

  P.p-right-side {
    left: 400px;
  }